<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen" id="allOrder">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">All Order</h1>
        <div class="flex items-center gap-6">
          <Button buttonText="Download" type="secondary" disabled />
        </div>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="grid grid-cols-6 justify-start gap-2 mb-4">
          <div>
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Sort By</label>
            <Dropdown :options="sortByList" optionLabel="name" v-model="filterSelected['sort']" placeholder="Last Updated" :isReset="isReset" />
          </div>
          <div>
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Status</label>
            <Dropdown :options="statusOptions" optionLabel="name" v-model="filterSelected['status']" placeholder="All" :isReset="isReset" />
          </div>
          <div v-if="filterStatus !== 'WAITING_FOR_PAYMENT' && filterStatus !== 'PENDING'">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Type</label>
            <Dropdown :options="paymentTypeOptions" optionLabel="name" v-model="filterSelected['paymentType']" placeholder="All" :isReset="isReset" />
          </div>
          <div class="">
            <TextField type="date" label="Tanggal Awal" v-model="payload.transactionTimeStart" borderEnabled placeholder="Select Date" />
          </div>
          <div class="">
            <TextField type="date" label="Tanggal Akhir" v-model="payload.transactionTimeEnd" borderEnabled placeholder="Select Date" />
          </div>
          <div class="grid grid-cols-2 h-full mt-5 ml-2">
            <Button buttonText="Filter" type="primary" @action="onSearch" additionalClass="mr-1 bg-yellow h-11" />
            <Button buttonText="Clear" type="tertiary" @action="clearParameter" additionalClass=" h-11" v-if="isClearBtnActive" />
          </div>
        </div>
        <div class="flex items-center mb-7">
          <div class="w-1/4">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search Order </label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="onSearch" @onBlur="onBlur" v-model="keyword" withIcon borderEnabled placeholder="Search order Id or email" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search color="#58595B" />
              </div>
            </div>
          </div>
          <div class="flex items-center h-full ml-3 mt-4">
            <Button buttonText="Search" type="primary" @action="onSearch(true)" @focus="onBlur(true)" additionalClass="bg-yellow h-11" />
          </div>
        </div>
        <vue-good-table
          v-if="isTableVisible"
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
            setCurrentPage: currentPage
          }"
          :totalRows="totalRecords"
          :rows="dataOfTable"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2">
              <Button buttonText="Detail" type="primary" size="full" @action="toDetail(props.row.id)" />
            </div>
            <div v-else-if="props.column.field === 'id'" class="cursor-pointer hover:text-yellow" @click="toDetail(props.row.id)">
              {{ props.row[props.column.field] }}
            </div>
            <div v-else-if="props.column.field === 'transactionTime'" class="w-36">
              {{ props.row[props.column.field] }}
            </div>
            <div v-else-if="props.column.field === 'status'" class="w-56">
              <div class="w-max font-bold p-2 rounded-lg" :style="customStyle(props.row[props.column.field])">
                {{ props.row[props.column.field] }}
              </div>
            </div>
            <div v-else-if="props.column.field === 'customer_name'" class="text-yellow w-36">
              {{ props.row[props.column.field] }}
            </div>
            <div v-else-if="props.column.field === 'userName'" class="w-56">
              {{ props.row[props.column.field] }}
            </div>
            <div v-else>
              {{ props.row[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { currencyFormat } from '@/utils/currency'
import { showVueToast } from '@/utils'
export default {
  name: 'AllOrder',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: '',
        nama: ''
      },
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      selectedDP: null,
      // New Value
      isTableVisible: true,
      currentPage: 1,
      isReset: false,
      keyword: '',
      payload: {
        id: '',
        paymentType: [],
        status: [],
        transactionId: '',
        transactionTimeEnd: '',
        transactionTimeStart: '',
        userEmail: '',
        userId: '',
        customerId: this.clientId,
        userLogonId: this.userId
      },
      filterSelected: {
        sort: null,
        status: null,
        paymentType: null,
        transactionTimeStart: '',
        transactionTimeEnd: ''
      },
      isKeywordValid: false,
      isSearchKeyword: false,
      isSearchButtonClicked: false,
      warning: '',
      params: {
        page: 0,
        size: 10,
        sort: ''
      },
      totalRecords: 0,
      dataOfTable: [],
      sortByList: [
        {
          id: 'createdDate,Desc',
          name: 'Last Updated'
        },
        {
          id: 'createdDate,Asc',
          name: 'From Oldest'
        }
      ],
      paymentTypeOptions: [
        {
          id: 'bank_transfer',
          name: 'Transfer'
        },
        {
          id: 'voucher',
          name: 'Voucher'
        }
      ],
      statusOptions: [
        {
          id: 'PAID',
          name: 'Sukses'
        },
        {
          id: 'PENDING',
          name: 'Menunggu Pembayaran'
        },
        {
          id: 'WAITING_FOR_PAYMENT',
          name: 'Lanjutkan Pembayaran'
        },
        {
          id: 'FAILED',
          name: 'Gagal'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('common', ['digitalPlatforms', 'refreshPage']),
    isClearBtnActive() {
      if (
        this.filterSelected.sort !== null ||
        this.filterSelected.status !== null ||
        this.filterSelected.paymentType !== null ||
        this.payload.transactionTimeStart !== '' ||
        this.payload.transactionTimeEnd !== '' ||
        this.keyword !== ''
      ) {
        return true
      }
      return false
    },
    filterStatus() {
      if (this.filterSelected.status === null) {
        return null
      }
      return this.filterSelected.status.id
    },
    filterType() {
      if (this.filterSelected.paymentType === null) {
        return null
      }
      return this.filterSelected.paymentType.id
    },
    clientId() {
      return localStorage.getItem('client')
    },
    userId() {
      return localStorage.getItem('id')
    },
    allOrder() {
      return this.$store.getters['order/allOrder'] ? this.$store.getters['order/allOrder'] : {}
    },
    columns() {
      return [
        { field: 'id', label: 'Order Id' },
        { field: 'transactionTime', label: 'Order Date' },
        { field: 'userName', label: 'Customer Name' },
        { field: 'userEmail', label: 'Email' },
        { field: 'status', label: 'Status' },
        { field: 'programType', label: 'Program Type' },
        { field: 'voucher', label: 'Voucher' },
        { field: 'voucherAmount', label: 'Voucher Amount' },
        { field: 'paidAmount', label: 'Paid Amount' },
        { field: 'timestamp', label: 'Status Timestamp' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    }
  },
  watch: {
    keyword(val) {
      if (val === '') {
        this.payload.id = ''
      }
      if (!this.isSearchKeyword) return
      if (val.includes('@')) {
        this.payload.userEmail = val
        this.isKeywordValid = true
        this.payload.id = ''
        return
      } else {
        if (isNaN(val) === true) {
          this.isKeywordValid = false
          this.warning = 'Order id not valid'
          return
        }
        if (val.length !== 15) {
          this.isKeywordValid = false
          this.warning = 'Order id must be 15 numbers !'
          return
        }
      }
      this.payload.userEmail = ''
      this.payload.id = val
      this.isKeywordValid = true
      // this.payload.id = val
    },
    refreshPage() {
      this.clearParameter()
    },
    filterType(val) {
      if (val === 'voucher') {
        this.statusOptions = [
          {
            id: 'PAID',
            name: 'Sukses'
          },
          {
            id: 'FAILED',
            name: 'Gagal'
          }
        ]
        return
      }
      this.statusOptions = [
        {
          id: 'PAID',
          name: 'Sukses'
        },
        {
          id: 'PENDING',
          name: 'Menunggu Pembayaran'
        },
        {
          id: 'WAITING_FOR_PAYMENT',
          name: 'Lanjutkan Pembayaran'
        },
        {
          id: 'FAILED',
          name: 'Gagal'
        }
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading']),
    toDetail(orderId) {
      this.$router.push(`order/${orderId}`)
    },
    customStyle(status) {
      let result = ''
      switch (status) {
        case 'Sukses':
          result = 'background: #D5F6E3; color:#27AE60'
          break
        case 'Menunggu Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break

        case 'Lanjutkan Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    onPageChange(params) {
      this.currentPage = params.currentPage
      this.params.page = params.currentPage - 1
      this.params.size = params.currentPerPage
      this.getData()
    },
    async onSearch(isBtnSearch) {
      if (this.isSearchKeyword) {
        this.isSearchButtonClicked = true
        setTimeout(() => {
          this.isSearchButtonClicked = false
        }, 500)
      }

      if (this.isSearchKeyword && !this.isKeywordValid && this.keyword !== '') {
        return showVueToast(this.warning, 'warning', 2000)
      }

      // if(isBtnSearch) {
      //   this.isSearchKeyword = true
      // }
      this.params.page = 0
      this.currentPage = 1
      this.checkParams(this.filterSelected)
      this.showLoading()
      this.getData()
    },

    onBlur(state) {
      if (this.keyword === '' && !this.isKeywordValid) {
        this.isSearchKeyword = state
      } else {
        this.isSearchKeyword = state
      }
    },

    async clearParameter() {
      this.showLoading()
      this.isReset = !this.isReset
      this.keyword = ''
      this.filterSelected = {
        sort: null,
        status: null,
        paymentType: null,
        transactionTimeStart: '',
        transactionTimeEnd: ''
      }
      this.payload = {
        id: '',
        paymentType: [],
        status: [],
        transactionTimeEnd: '',
        transactionTimeStart: '',
        userEmail: '',
        userId: '',
        customerId: this.clientId,
        userLogonId: this.userId
      }

      this.params = {
        page: 0,
        size: 10,
        sort: ''
      }

      this.getData()
    },

    checkParams(data) {
      for (const property in data) {
        switch (property) {
          case 'sort':
            this.params.sort = this.isObjectData(data['sort'], '')
            break
          case 'status':
            this.filterSelected['status'] === null ? '' : (this.payload.status[0] = this.isObjectData(data['status'], []))
            break
          case 'paymentType':
            this.filterSelected['paymentType'] === null ? '' : (this.payload.paymentType[0] = this.isObjectData(data['paymentType'], []))
            break
          default:
            break
        }
      }

      return true
    },

    isVoucher(payment) {
      if (this.paymentType(payment).isVoucher) return 'Yes'
      return 'No'
    },
    paymentType(payment) {
      const result = { label: '', voucherPrice: 0, transferPrice: 0, isVoucher: false }

      switch (payment.paymentType) {
        case 'bank_transfer':
          payment.bank ? (result.label = `${payment.bank.toUpperCase()} `) : (result.label = 'Not Found')
          result.transferPrice = currencyFormat(payment.totalPrice)
          break
        case 'echannel':
          result.label = 'Mandiri'
          result.transferPrice = currencyFormat(payment.totalPrice)
          break
        case 'csstore':
          result.label = `${payment.store.toUpperCase()}`
          result.transferPrice = currencyFormat(payment.totalPrice)
          break
        default:
          result.label = 'Voucher'
          payment.status === 'WAITING_FOR_PAYMENT' ? '' : (result.isVoucher = true)
          payment.status === 'WAITING_FOR_PAYMENT' ? (result.transferPrice = currencyFormat(payment.totalPrice)) : (result.voucherPrice = currencyFormat(payment.totalDiscount))
          break
      }

      if (payment.status === 'WAITING_FOR_PAYMENT' || payment.status === 'FAILED') result.isVoucher = false
      return result
    },

    isObjectData(data, defaultValue) {
      if (typeof data === 'object' && data !== null) {
        return data.id
      }
      return defaultValue
    },
    translateStatus(status) {
      let result = { label: '', style: '' }
      switch (status) {
        case 'PAID':
          result.label = 'Sukses'
          result.style = 'background: #D5F6E3; color:#27AE60'
          break
        case 'PENDING':
          result.label = 'Menunggu Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break

        case 'WAITING_FOR_PAYMENT':
          result.label = 'Lanjutkan Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result.label = 'Gagal'
          result.style = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    getUserById(userId, index) {
      this.$store.dispatch('order/GET_USER_BY_USER_ID', { userId }).then((res) => {
        this.dataOfTable[index].userName = res.name
      })
    },
    getData() {
      this.isTableVisible = false
      this.payload.customerId = this.clientId
      this.payload.userLogonId = this.userId
      this.$store
        .dispatch('order/GET_ORDER_LIST', {
          params: this.params,
          payload: this.payload
        })
        .then(() => {
          this.dataOfTable = this.allOrder.content.map((item, index) => {
            this.getUserById(item.userId, index)
            return {
              ...item,
              programType: item.orderItems[0].type,
              customer_name: item.orderItems[0].customerName,
              transactionTime: moment(`${item.createdDate}.000+0000`).format('DD MMM YYYY HH:mm'),
              voucher: this.isVoucher(item),
              voucherAmount: this.paymentType(item).voucherPrice,
              paidAmount: this.paymentType(item).transferPrice,
              status: this.translateStatus(item.status).label,
              timestamp: moment(`${item.transactionTime}.000+0000`).format('DD MMM YYYY HH:mm')
            }
          })
          this.totalRecords = this.allOrder.totalElements
          setTimeout(() => {
            this.isTableVisible = true
            this.hideLoading()
            this.stillLoading = false
          }, 1000)
        })
    },
    /////////////////////////////////
    searchByEcommerce() {
      this.options['ecommerce'] = this.selectedDP.code
      this.onSearch()
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.nama-kelas {
  min-width: 300px;
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
