var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen",
    attrs: {
      "id": "allOrder"
    }
  }, [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("All Order")]), _c('div', {
    staticClass: "flex items-center gap-6"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Download",
      "type": "secondary",
      "disabled": ""
    }
  })], 1)]), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "grid grid-cols-6 justify-start gap-2 mb-4"
  }, [_c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Sort By")]), _c('Dropdown', {
    attrs: {
      "options": _vm.sortByList,
      "optionLabel": "name",
      "placeholder": "Last Updated",
      "isReset": _vm.isReset
    },
    model: {
      value: _vm.filterSelected['sort'],
      callback: function callback($$v) {
        _vm.$set(_vm.filterSelected, 'sort', $$v);
      },
      expression: "filterSelected['sort']"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Status")]), _c('Dropdown', {
    attrs: {
      "options": _vm.statusOptions,
      "optionLabel": "name",
      "placeholder": "All",
      "isReset": _vm.isReset
    },
    model: {
      value: _vm.filterSelected['status'],
      callback: function callback($$v) {
        _vm.$set(_vm.filterSelected, 'status', $$v);
      },
      expression: "filterSelected['status']"
    }
  })], 1), _vm.filterStatus !== 'WAITING_FOR_PAYMENT' && _vm.filterStatus !== 'PENDING' ? _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Type")]), _c('Dropdown', {
    attrs: {
      "options": _vm.paymentTypeOptions,
      "optionLabel": "name",
      "placeholder": "All",
      "isReset": _vm.isReset
    },
    model: {
      value: _vm.filterSelected['paymentType'],
      callback: function callback($$v) {
        _vm.$set(_vm.filterSelected, 'paymentType', $$v);
      },
      expression: "filterSelected['paymentType']"
    }
  })], 1) : _vm._e(), _c('div', {}, [_c('TextField', {
    attrs: {
      "type": "date",
      "label": "Tanggal Awal",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.payload.transactionTimeStart,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "transactionTimeStart", $$v);
      },
      expression: "payload.transactionTimeStart"
    }
  })], 1), _c('div', {}, [_c('TextField', {
    attrs: {
      "type": "date",
      "label": "Tanggal Akhir",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.payload.transactionTimeEnd,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "transactionTimeEnd", $$v);
      },
      expression: "payload.transactionTimeEnd"
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-2 h-full mt-5 ml-2"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Filter",
      "type": "primary",
      "additionalClass": "mr-1 bg-yellow h-11"
    },
    on: {
      "action": _vm.onSearch
    }
  }), _vm.isClearBtnActive ? _c('Button', {
    attrs: {
      "buttonText": "Clear",
      "type": "tertiary",
      "additionalClass": " h-11"
    },
    on: {
      "action": _vm.clearParameter
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "flex items-center mb-7"
  }, [_c('div', {
    staticClass: "w-1/4"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search Order ")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.onSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Search order Id or email"
    },
    on: {
      "onBlur": _vm.onBlur
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "#58595B"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "flex items-center h-full ml-3 mt-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "primary",
      "additionalClass": "bg-yellow h-11"
    },
    on: {
      "action": function action($event) {
        return _vm.onSearch(true);
      },
      "focus": function focus($event) {
        return _vm.onBlur(true);
      }
    }
  })], 1)]), _vm.isTableVisible ? _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All',
        setCurrentPage: _vm.currentPage
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.dataOfTable,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2"
        }, [_c('Button', {
          attrs: {
            "buttonText": "Detail",
            "type": "primary",
            "size": "full"
          },
          on: {
            "action": function action($event) {
              return _vm.toDetail(props.row.id);
            }
          }
        })], 1) : props.column.field === 'id' ? _c('div', {
          staticClass: "cursor-pointer hover:text-yellow",
          on: {
            "click": function click($event) {
              return _vm.toDetail(props.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")]) : props.column.field === 'transactionTime' ? _c('div', {
          staticClass: "w-36"
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")]) : props.column.field === 'status' ? _c('div', {
          staticClass: "w-56"
        }, [_c('div', {
          staticClass: "w-max font-bold p-2 rounded-lg",
          style: _vm.customStyle(props.row[props.column.field])
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")])]) : props.column.field === 'customer_name' ? _c('div', {
          staticClass: "text-yellow w-36"
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")]) : props.column.field === 'userName' ? _c('div', {
          staticClass: "w-56"
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")]) : _c('div', [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")])];
      }
    }], null, false, 161406517)
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }